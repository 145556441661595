<template>
	<el-aside width="230px">
		<el-menu 
			router
			background-color="#314156" 
			class="el-menu-vertical-demo" 
			text-color="#fff"
			active-text-color="#40a1e8" 
			:default-openeds="openeds"
			@open="handleOpen" 
			@close="handleClose"
			@select="handleSelect">
			<div v-for="(menu,index) in menus" :index="index" :key="menu.id">
				<el-menu-item v-if="menu.children.length==0" :index="menu.path" >
					<template slot="title">
						<i :class="menu.icon"></i>
						<span>{{menu.name}}</span>
					</template>
				</el-menu-item>
				<!-- 有下级菜单 -->
				<el-submenu v-if="menu.children.length>0" :index="menu.path">
					<template slot="title">
						<i :class="menu.icon"></i>
						<span>{{menu.name}}</span>
					</template>
					<div v-for="(firstChild,i) in menu.children" :index="index+i" :key="firstChild.id">
						<el-menu-item v-if="firstChild.children.length==0" :index="firstChild.path" >
							<template slot="title">
								<i :class="firstChild.icon"></i>
								<span>{{firstChild.name}}</span>
							</template>
						</el-menu-item>
						<el-submenu v-if="firstChild.children.length>0" :index="firstChild.path">
							<template slot="title">
								<i :class="firstChild.icon"></i>
								<span>{{firstChild.name}}</span>
							</template>
							<div v-for="(secondChild,j) in firstChild.children" :index="index+i+j" :key="secondChild.id">
								<el-menu-item :index="secondChild.path">
									<template slot="title">
										<i :class="secondChild.icon"></i>
										<span>{{secondChild.name}}</span>
									</template>
								</el-menu-item>
							</div>
						</el-submenu>
					</div>
				</el-submenu>
			</div>
		</el-menu>
	</el-aside>
</template>

<script>
	import menus from '@/api/menu'
	export default {
		name: "Left",
		data() {
			return {
				openeds: ['/admin/index'],
				menus: menus,
				indexBreadcrumbs: [],
			}
		},
		methods: {
			handleOpen(key, keyPath) {
				console.log(key, keyPath);
			},
			handleClose(key, keyPath) {
				console.log(key, keyPath);
			},
			handleSelect (index, indexPath) {
				this.indexBreadcrumbs = indexPath
				let breadcrumbs = []
				let menuList = this.menus
				this.indexBreadcrumbs.map(item => {
					for (let i = 0; i < menuList.length; i++) {
						if (item === menuList[i].path) {
							let param={
								id:menuList[i].id,
								name:menuList[i].name
							}
							breadcrumbs.push(param)
							if (menuList[i].children) {
								menuList = menuList[i].children
							}
						}
					}
				})
				// console.log("子面包屑",breadcrumbs)
				this.$emit('getBreadcrumbList',breadcrumbs)
			}
		}
	}
</script>

<style scoped>
	.el-aside {
		background-color: #314156;
		top: 60px;
	}
</style>
