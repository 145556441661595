<template>
	<div v-title data-title="三特科技信息管理系统">
		<el-container class="container" v-title data-title="三特科技信息管理系统">
			<!-- 头部 -->
			<el-header class="header">
				<Header></Header>
			</el-header>
			<!-- 主体部分 -->
			<el-container>
				<!-- 左侧导航栏 -->
				<Left @getBreadcrumbList="updateBreadcrumb"></Left>
				<!-- 操作区 -->
				<el-main>
					<el-breadcrumb separator-class="el-icon-arrow-right" style="padding-left: 15px">
						<el-breadcrumb-item :to="{ path: '/admin/index' }">
							<span style="font-size: 16px; font-weight: 550;">首页</span>
						</el-breadcrumb-item>
						<el-breadcrumb-item v-for="(item,index) in breadcrumbList" :index="index" :key="item.id">{{item.name}}</el-breadcrumb-item>
					</el-breadcrumb>
					<el-card>
						<router-view></router-view>
					</el-card>
				</el-main>
			</el-container>
		</el-container>
	</div>
</template>

<script>
	import Header from "../../components/a/Header"
	import Left from "../../components/a/Left";

	export default {
		name: "Index",
		components: {
			Header,
			Left
		},
		data() {
			return {
				breadcrumbList:[],
			}
		},
		methods: {
			updateBreadcrumb(item){
				this.breadcrumbList=[]
				for(let i=0;i<item.length;i++){
					if(item[i].name!='首页'){
						this.breadcrumbList.push(item[i])
					}
				}
				// console.log("父面包屑",this.breadcrumbList)
			}
		}
	}
</script>

<style scoped>
	.header {
		background-color: #0081BD;
		width: 100%;
	}
	.container {
		background-color: #d3dce6;
		position: absolute;
		bottom: 0px;
		top: 0px;
		left: 0px;
		right: 0px;
	}
	
	.el-main {
		position: absolute;
		left: 230px;
		right: 0px;
		top: 60px;
		bottom: 0px;
		padding: 0px;
		margin: 0px;
	}
	.el-breadcrumb {
		position: relative; 
		border-bottom:1px solid #b3c0d1;
		height: 45px;
		line-height: 45px;
		padding: 0px;
		margin: 0px;
	}
	.el-card{
		position: absolute;
		left: 0px;
		right: 0px;
		top: 45px;
		bottom: 0px;	
	}
</style>
