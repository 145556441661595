const data = [{
		id: '1',
		name: '首页',
		icon: 'el-icon-s-home',
		path: '/admin/index',
		children: [
			{
				id: '1-1',
				name: '权限管理',
				icon: 'el-icon-caret-right',
				path: '/admin/authority/',
				children: [
					{
						id: '1-1-1',
						name: '用户管理',
						path: '/admin/authority/userindex'
					},
					{
						id: '1-1-3',
						name: '日志管理',
						path: '/admin/authority/loggerindex'
					}
				]
			},
			{
				id: '1-2',
				name: '内容管理',
				icon: 'el-icon-caret-right',
				path: '/admin/content/',
				children: [
					{
						id: '1-2-1',
						name: '栏目管理',
						path: '/admin/content/channelindex'
					},
					{
						id: '1-2-2',
						name: '轮播图管理',
						path: '/admin/content/SlideshowIndex'
					},
					// {
					// 	id: '1-2-4',
					// 	name: '标签管理',
					// 	path: '/admin/content/TagIndex'
					// }
				]
			},
			{
				id: '1-3',
				name: '业务管理',
				icon: 'el-icon-caret-right',
				path: '/admin/business/',
				children: [
					{
						id: '1-3-1',
						name: '业务景区',
						path: '/admin/business/scenicindex'
					},
					{
						id: '1-3-2',
						name: '软件成果',
						path: '/admin/business/softwareindex'
					}
				]
			},
			{
				id: '1-4',
				name: '新闻管理',
				icon: 'el-icon-caret-right',
				path: '/admin/news/',
				children: [
					{
						id: '1-4-1',
						name: '文章管理',
						path: '/admin/news/articleindex'
					},
				]
			},
			{
				id: '1-5',
				name: '招聘管理',
				icon: 'el-icon-caret-right',
				path: '/admin/recruit/',
				children: [
					{
						id: '1-5-1',
						name: '岗位管理',
						path: '/admin/recruit/recruitindex'
					}
				]
			},
		]
	}

]

export default data
